import { Tooltip as AntTooltip, TooltipProps } from 'antd'
import { FC } from 'react'
import styled from 'styled-components'

type BaseProps = TooltipProps & {
  className: string
}
const BaseTooltip: FC<BaseProps> = ({ className, ...rest }) => (
  <AntTooltip overlayClassName={className} placement="bottom" {...rest} />
)

type ExtraTooltipProps = {
  $minimalPadding?: boolean
  $withoutPadding?: boolean
  $widerWidth?: boolean
}

type Props = Omit<BaseProps, 'className'> & ExtraTooltipProps

export const Tooltip: FC<Props> = styled(BaseTooltip)<ExtraTooltipProps>`
  .ant-tooltip-inner {
    ${({ $widerWidth }) => ($widerWidth ? 'min-width: 22rem;' : '')}
    width: fit-content;
    color: black;
    background-color: ${({ theme }) => theme.colors.bgSecondary};
    border-radius: ${({ theme }) => theme.border.radiusSmall};
    padding: ${({ $minimalPadding, $withoutPadding }) => {
      if ($withoutPadding) {
        return '0'
      }
      if ($minimalPadding) {
        return '0.375rem 0.5rem'
      }
      return '1rem'
    }};
    border: 1px solid ${({ theme }) => theme.colors.architecture3};
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .ant-tooltip-arrow {
    display: none;
  }
`
