import { Button, ButtonProps } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { SubmitButton } from 'formik-antd'
import { FC } from 'react'
import styled, { css } from 'styled-components'
import { ThemeColor } from '../../themes'

const sharedStyles = css<{ $fullWidth?: boolean; $size?: SizeType }>`
  height: ${({ $size }) => ($size === 'small' ? '2rem' : $size === 'large' ? '3rem' : '2.5rem')};
  padding: 0 1.25rem;
  box-sizing: border-box;
  border-width: ${({ theme }) => theme.border.width};
  border-radius: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};

  &:disabled {
    opacity: ${({ theme }) => theme.opacity.disabled};
    pointer-events: none;

    &:hover {
      box-shadow: none;
    }
  }
`

const primaryButtonSharedStyles = css`
  background-color: ${({ theme }) => theme.colors.black};
  border-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.typeDarkPrimary};

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.actionPrimary};
    background-color: ${({ theme }) => theme.colors.black};
  }

  &:disabled,
  &:disabled:hover {
    background-color: ${({ theme }) => theme.colors.typeSecondary};
    border-color: ${({ theme }) => theme.colors.typeSecondary};
    color: ${({ theme }) => theme.colors.typeDarkPrimary};
  }
`

const secondaryButtonSharedStyles = css`
  background-color: ${({ theme }) => theme.colors.bgSecondary};
  border-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.black};

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.bgPrimary};
    border-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.black};
  }

  &:disabled,
  &:disabled:hover {
    background-color: transparent;
    border-color: ${({ theme }) => theme.colors.typeSecondary};
    color: ${({ theme }) => theme.colors.typeSecondary};
  }
`

export const ButtonWrapper = styled.div<{
  disabled?: boolean
  $fullWidth?: boolean
}>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
`

export const PrimaryButton = styled(Button)<{ $fullWidth?: boolean; $size?: SizeType }>`
  ${sharedStyles};
  ${primaryButtonSharedStyles};
`

export const PrimarySubmitButton = styled(SubmitButton)<{ $fullWidth?: boolean; $size?: SizeType }>`
  ${sharedStyles};
  ${primaryButtonSharedStyles};
`

export const SecondaryButton = styled(Button)<{ $fullWidth?: boolean; $size?: SizeType }>`
  ${sharedStyles};
  ${secondaryButtonSharedStyles};
`

export const TertiaryButton = styled(Button)<{ $fullWidth?: boolean; $size?: SizeType }>`
  ${sharedStyles};
  ${secondaryButtonSharedStyles};
  background-color: ${({ theme }) => theme.colors.actionPrimary};
`

export const NewPrimaryButton = styled(Button)<{ $fullWidth?: boolean; $size?: SizeType }>`
  ${sharedStyles};
  ${secondaryButtonSharedStyles};
  background-color: ${({ theme }) => theme.colors.actionPrimary};

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.architecture8}0D;
  }
`

export const NewSecondaryButton = styled(Button)<{
  $fullWidth?: boolean
  $size?: SizeType
  $transparent?: boolean
}>`
  ${sharedStyles};
  ${secondaryButtonSharedStyles};

  background-color: ${({ theme, $transparent }) =>
    $transparent ? 'transparent' : theme.colors.bgSecondary};

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.architecture8}0D;
  }
`

export const GhostButton = styled(Button)<{ $fullWidth?: boolean; $size?: SizeType }>`
  ${sharedStyles};
  border: none;
  box-shadow: none;
  background-color: transparent;
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.architecture8}0D;
  }

  &:disabled,
  &:disabled:hover {
    background-color: transparent;
  }
`

export const WarningButton = styled(Button)<{ $fullWidth?: boolean; $size?: SizeType }>`
  ${sharedStyles};
  border-color: ${({ theme }) => theme.colors.semanticNegative};
  color: ${({ theme }) => theme.colors.semanticNegative};

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.semanticNegative};
    color: ${({ theme }) => theme.colors.semanticNegative};
    background-color: ${({ theme }) => theme.colors.semanticNegative}0D;
  }
`

// Most submit buttons are black, but on the login page we have a few
// buttons in green, so need a separate button type for those.
export const TertiarySubmitButton = styled(SubmitButton)<{
  $fullWidth?: boolean
  $size?: SizeType
}>`
  ${sharedStyles};
  ${secondaryButtonSharedStyles};
  background-color: ${({ theme }) => theme.colors.actionPrimary};
`

export const IconButton = styled(Button)<{ $fullWidth?: boolean; $size?: SizeType }>`
  ${sharedStyles};
  ${secondaryButtonSharedStyles};
  border: none;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
`

export const DangerButton = styled(Button)<{ $fullWidth?: boolean; $size?: SizeType }>`
  ${sharedStyles};
  background-color: transparent;
  border-color: ${({ theme }) => theme.colors.semanticNegative};
  color: ${({ theme }) => theme.colors.semanticNegative};

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.bgPrimary};
    border-color: ${({ theme }) => theme.colors.semanticNegative};
    color: ${({ theme }) => theme.colors.semanticNegative};
  }

  &:disabled,
  &:disabled:hover {
    background-color: transparent;
    border-color: ${({ theme }) => theme.colors.semanticNegative};
    color: ${({ theme }) => theme.colors.semanticNegative};
    opacity: 0.4;
  }
`

export const PositiveButton = styled(Button)<{ $fullWidth?: boolean; $size?: SizeType }>`
  ${sharedStyles};
  ${secondaryButtonSharedStyles};
  color: ${({ theme }) => theme.colors.semanticPositive};
  border-color: ${({ theme }) => theme.colors.semanticPositive};
`

const withDefaultLinkProps = (Component: FC<ButtonProps>) => (props: ButtonProps) => (
  <Component type="link" {...props} />
)

type LinkButtonProps = {
  fontWeight?: string
  color?: ThemeColor
}

const StyledLinkButton = styled(Button)<LinkButtonProps>`
  display: flex;
  align-items: center;
  font-weight: ${({ fontWeight }) => fontWeight || 700};
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.actionLink)};
`

export const LinkButton = withDefaultLinkProps(StyledLinkButton)
